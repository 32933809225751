.error-indicator {
    display: flex;
    flex-direction: column;;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: grey;
    color: yellow;
}

.error-indicator img {
    margin-bottom: 1rem;
}

.error-indicator .boom {
    font-size: 1.7rem;;
}