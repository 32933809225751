@import '~bootstrap/scss/bootstrap.scss';

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
}

.overflow {
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .3);
        z-index: 999;
    }
}

.platform-content-wrapper {
    position: relative;
    padding: 20px;
    padding-bottom: 80px;

    .item-wrapper {
        padding-top: 20px;
    }
}

.pot-viewer-table {
    width: 100%;
    border: 1px solid #7c818d;
    svg {
        margin-right: 5px;
    }
    tr th {
        padding: 15px;
        font-size: 12px;
        font-weight: 600;
        border-right: 1px solid #e0e0e0;
        &:last-child {
            border-right: none;
        }
    }
    tr td {
        padding: 15px;
        font-size: 12px;
        border-right: 1px solid #e0e0e0;
        &:first-child {
            width: 15%;
        }
        &:nth-child(2) {
            width: 11%;
        }
        &:nth-child(3) {
            width: 15%;
        }
        &:nth-child(4) {
            width: 17%;
        }
        &:nth-child(5) {
            width: 17.5%;
        }
        &:nth-child(6) {
            width: 11.5%;
        }
        &:nth-child(7) {
            width: 13%;
        }
        &:last-child {
            border-right: none;
        }
    }

    input {
        display: block;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        padding-right: 35px;
        border: none;

    }

    .search-row {
        td {
            position: relative;
            padding: 0;
            border: 1px solid #0c76cb;
            border-radius: 2px;
        }

        button {
            position: absolute;
            top: 50%;
            right: 5px;
            background: transparent;
            border: none;
            outline: none;
            text-align: center;
            transform: translateY(-50%)
        }

        i {
            font-size: 13px;
            color: #7C818D;
        }
    }

    tbody tr:not(.search-row):nth-child(2n) {
        background: #F5F5F5;
    }

    .search-icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%)
    }
}

@media (max-width: 992px) {

    input[type="text"] {
        font-size: 16px;
    }

    .platform-content-wrapper {
        padding: 20px 15px;
        padding-bottom: 120px;
    }

    .pot-viewer-table-wrapper {
        margin-right: -15px;
        overflow: hidden;
        overflow-x: scroll; 
    }

    .MuiTabs-root {
        margin-right: -15px;
    }

    .pot-viewer-table {
        width: 200%;
    }
}

@media (max-width: 768px) {
    .pot-viewer-table {
        width: 300%;
    }

    .platform-content-wrapper {
        padding-top: 15px;
    }

    button {
        outline: none !important;
    }
}

@media (max-width: 425px) {
    .pot-viewer-table {
        width: 400%;
    }
}