.logo-block {
    display: flex;
    align-items: center;
    padding: 5px;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        opacity: .7;
    }
}

@media (max-width: 992px) {
    .logo-block img {
        opacity: 1;
    }
}