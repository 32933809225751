.search-field {
    display: block;
    width: 100%;
    height: 30px;
    margin: 20px 0;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 1.4285143;
    color: #464545;
    background-color: #fff;
    border: 1px solid #C4CBD9;
}