.platform-header {
    padding: 15px 20px;
    background: #082c46;

    .row {
        margin: 0;
    }
}

@media (max-width: 992px) {
    .platform-header {
        padding: 0;

        .top {
            padding: 5px 20px;
        }

        .bottom {
            padding: 35px 20px;
            border-top: 1px solid #244761;
            border-bottom: 1px solid #244761;
        }

        .hamburger {
            display: inline-block;
        }

        .row {
            flex-direction: row-reverse;
        }
    }

    .mobile-nav-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
    }

    .navbar-header {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;

        a {
            padding: 10px 5px;
        }
    }
}