.hamburger {
  font: inherit;
  display: inline-block;
  vertical-align: bottom;
  overflow: visible;
  height: 24px;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-support {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-support, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: "";
}

.hamburger-inner:before {
  top: 0px;
}

.hamburger-inner:after {
  bottom: 0px;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
  background-color: #fff;
}

.hamburger--arrow.is-active .hamburger-inner:before {
  transform: translate3d(-8px,0,0) rotate(-45deg) scaleX(.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  transform: translate3d(-8px,0,0) rotate(45deg) scaleX(.7);
}

.hamburger--arrow.is-active {
  .hamburger-support {
    opacity: 0;
  }

  .hamburger-inner:before {
    top: 1px;
  }

  .hamburger-inner:after {
    bottom: 1px;
  }
}

@media (min-width: 992px) {
  .hamburger {
    display: none;
  }
}