.tree {
    position: relative;
    margin-bottom: 0;
    padding-left: 20px;
    list-style: none;
    ul {
        position: relative;
        margin: 0;
        padding-left: 20px;
        list-style: none;

        li {
            &::before {
                top: -6px;
            }

            &:last-child {
                &::before {
                    height: 19px;
                }
            }
        }
    }

    li {
        position: relative;
        &.active {
            & > .expand > svg {
                fill: #0095FF;
            }

            & > .content > .pseudo-link {
                color: #0095FF;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 13px;
            left: 0;
            width: 1px;
            height: 100%;
            background: #A4A5A7;
        }

        &:last-child {
            &::before {
                height: 1px;
            }
        }
    }

    .checker {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 8px;
        left: -6px;
        width: 12px;
        height: 12px;
        font-size: 11px;
        font-weight: bold;
        background: #A4A5A7;
        color: #EBEDEF;
        cursor: pointer;

        &.on {
            background: #0095FF;
            color: #fff;
        }
    }

    svg {
        fill: #A4A5A7;
    }

    a, .disabled {
        padding-left: 5px;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        font-family: inherit;
        color: #313131;
    }

    .disabled {
        opacity: .7;
    }
}

.catch-active-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;

    .active {
        color: #0095FF;

        & + .expand svg {
            fill: #0095FF;
        }
    }
}

@media (max-width: 992px) {
    .tree {
        padding-left: 5px;
    }
}